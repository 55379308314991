import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useForm, FormProvider } from "react-hook-form";
import { NotificationManager } from "react-notifications";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers";
import * as dayjs from "dayjs";
import IntlMessages, { IntlGetText } from "util/IntlMessages";
import { Grid } from "@material-ui/core";
import FormSelectAutoComplete from "components/Form/Controls/select-autocomplete";
import FormInput from "components/Form/Controls/input";
import FormDatePicker from "components/Form/Controls/datepicker";
import ButtonAsync from "components/Form/Controls/ButtonAsync";
import CardFileUpload from "components/Form/CardFileUpload";
import ApproveFormula from "./ApproveFormula";
import {
  callApi,
  callApiAsync,
  getGetFormula,
  getCitiesByStateProvinceId,
  getDiseasesByProductId,
  getDoctorList,
  postSaveFormula,
  postFormulaFile,
} from "api/apiList";
import {
  PrescriptionTypeList,
  DurationTreatmentList,
  PharmaceuticalUnitList,
  FormulaTypeList,
  PrescripStateEnum,
  FormulaType,
  PrescriptionType,
  ProductBehavior,
  SupplyFrequencyList
} from "constants/Enums";
import { setPrescriptionState } from "actions/Management";
import { setFormFormulaDone } from "actions/Impact";
import { setLoader } from "actions/Base";


const getCboValue = (items, id) => {
  if (typeof id == "object") {
    return id;
  }
  const result = items.find((x) => x.id === id);
  return result ? { label: result.name, value: result.id } : "";
};

const getProductBehavior = (productList, productId) => {
  const product = productList.find((x) => x.id == productId);
  if(!product)
    return -1;
  return product.customBehaviorId;
};

const useIsMounted = () => {
  const isMounted = useRef(false);

  useEffect(() => {
    isMounted.current = true;
    return () => (isMounted.current = false);
  }, []);

  return isMounted;
};

const FormulaForm = ({
  stateProvinceList,
  productList,
  handleRequestClose,
  openDlg,
}) => {
  const dispatch = useDispatch();
  const fnSetLoader = (data) => dispatch(setLoader(data));
  const { selectedPrescription } = useSelector(({ management }) => management);
  const { expeditionDateFormula } = useSelector(({ impact }) => impact);

  const [productBehavior, setProductBehavior] = useState(-1);

  const prescriptionTypeList = PrescriptionTypeList();
  const supplyFrequencyList = SupplyFrequencyList();

  const msgSuccess = IntlGetText("core.SuccessSave");
  const msgFileWarning = IntlGetText("core.fileNotFound");

  const componentIsMounted = useIsMounted();

  const getDInfo = (formulaData, prop) => {
    if (!formulaData) return "";

    //if (!formulaData.dosage) return "";

    return formulaData.dosage[prop] ? formulaData.dosage[prop] : "";
  };

  var defaultValues = {
    stateProvinceId: "",
    cityId: "",
    expeditionDate: null,
    code: "",
    companyId: "",
    visitTargetId: "",
    attentionScopeId: "",
    diseaseId: "",
    prescriptionType: "",
    productId: "",
    quantity: "",
    dosageUnitId: "",
    //infusionRate: "",
    supplyFrequency: "",
    administrationRouteId: "",
    medicineAmount: "",
    pharmaceuticalUnit: "",
    durationTreatment: "",
    formulaType: "",
    pharmaceuticalQuantity:"",
    additionalGuidelines: ""
  };

  const validationSchema = {
    formulaType: yup
      .object()
      .shape({ value: yup.string().required() })
      .required(<IntlMessages id="core.fieldRequired" />)
      .nullable(),
    productId: yup
      .object()
      .shape({ value: yup.string().required() })
      .required(<IntlMessages id="core.fieldRequired" />)
      .nullable(),
    diseaseId: yup
      .object()
      .shape({ value: yup.string().required() })
      .required(<IntlMessages id="core.fieldRequired" />)
      .nullable(),
    stateProvinceId: yup
      .object()
      .shape({ value: yup.string().required() })
      .required(<IntlMessages id="core.fieldRequired" />)
      .nullable(),
    cityId: yup
      .object()
      .shape({ value: yup.string().required() })
      .required(<IntlMessages id="core.fieldRequired" />)
      .nullable(),
    prescriptionType: yup
      .object()
      .shape({ value: yup.string().required() })
      .required(<IntlMessages id="core.fieldRequired" />)
      .nullable(),
    attentionScopeId: yup.object().when("formulaType", {
      is: (formulaType) =>
        formulaType && formulaType.value == FormulaType.MIPRES,
      then: yup
        .object()
        .shape({ value: yup.string().required() })
        .required(<IntlMessages id="core.fieldRequired" />)
        .nullable(),
    }),
    expeditionDate: yup
      .date()
      .nullable(true)
      .typeError(IntlGetText("yup.InvalidDate"))
      .required(<IntlMessages id="core.fieldRequired" />),
    code: yup
      .string()
      .when("formulaType", (formulaType) => {
        if (formulaType && formulaType.value == FormulaType.MIPRES) {
          return yup
            .string()
            .required(<IntlMessages id="core.fieldRequired" />);
        }
      })
      .matches(/^[a-z0-9_-]+$/i, IntlGetText("yup.alphanumericValidation")),
    visitTargetId: yup
      .object()
      .shape({ value: yup.string().required() })
      .required(<IntlMessages id="core.fieldRequired" />)
      .nullable(),
    companyId: yup
      .object()
      .shape({ value: yup.string().required() })
      .required(<IntlMessages id="core.fieldRequired" />)
      .nullable(),
    durationTreatment: yup
      .object()
      .shape({ value: yup.string().required() })
      .required(<IntlMessages id="core.fieldRequired" />)
      .nullable(),
    administrationRouteId: yup
      .object()
      .shape({ value: yup.string().required() })
      .required(<IntlMessages id="core.fieldRequired" />)
      .nullable(),
    medicineAmount: yup
      .number()
      .typeError(IntlGetText("yup.numericValidation"))
      .min(1, IntlGetText("yup.numericValidation"))
      .max(10000, IntlGetText("yup.invalidInput"))
      .required(<IntlMessages id="core.fieldRequired" />),
    pharmaceuticalUnit: yup
      .object()
      .shape({ value: yup.string().required() })
      .required(<IntlMessages id="core.fieldRequired" />)
      .nullable(),
    //Dosage
    dosageUnitId: yup
      .object()
      .shape({ value: yup.string().required() })
      .required(<IntlMessages id="core.fieldRequired" />)
      .nullable(),
    quantity: yup
      .number()
      .typeError(IntlGetText("yup.numericValidation"))
      .min(1, IntlGetText("yup.numericValidation"))
      .max(50000, IntlGetText("yup.invalidInput"))
      .required(<IntlMessages id="core.fieldRequired" />),
    supplyFrequency: yup.object().when(["formulaType","productId"], {
      is: (formulaType, productId) => 
        formulaType && formulaType.value == FormulaType.MIPRES &&
        productId && getProductBehavior(productList, productId.value) != ProductBehavior.remodulin
        && getProductBehavior(productList, productId.value) != ProductBehavior.dacepton,
      // is: (formulaType) => 
      //   formulaType && formulaType.value == FormulaType.MIPRES,
      then: yup
        .object()
        .shape({ value: yup.string().required() })
        .required(<IntlMessages id="core.fieldRequired" />)
        .nullable(),
    }),
    cityId: yup
      .object()
      .shape({ value: yup.string().required() })
      .required(<IntlMessages id="core.fieldRequired" />)
      .nullable(),
    // infusionRate: yup.number().when("productId", {
    //   is: (productId) =>
    //     productId &&
    //     (getProductBehavior(productList, productId.value) == ProductBehavior.remodulin
    //       || getProductBehavior(productList, productId.value) == ProductBehavior.dacepton),
    //   then: yup
    //     .number()
    //     .typeError(IntlGetText("yup.numericValidation"))
    //     .test("maxValidation", IntlGetText("yup.invalidInput"), function (value) {
    //       const { productId } = this.parent;
    //       if (productId && getProductBehavior(productList, productId.value) === ProductBehavior.remodulin) {
    //         return value <= 1;
    //       }
    //       return value <= 5000;
    //     })
    //     .test("minValidation", IntlGetText("yup.invalidInput"), function (value) {
    //       const { productId } = this.parent;
    //       if (productId && getProductBehavior(productList, productId.value) === ProductBehavior.remodulin) {
    //         return value > 0;
    //       }
    //       return value >= 0;
    //     })
    //     .required(<IntlMessages id="core.fieldRequired" />),
    // }),
  };

  const methods = useForm({
    defaultValues,
    resolver: yupResolver(yup.object().shape(validationSchema)),
  });
  const { handleSubmit, watch, setValue, errors } = methods;

  const stateProvinceIdValue = watch("stateProvinceId");
  const cityIdValue = watch("cityId");
  const companyIdValue = watch("companyId");
  const attentionScopeIdValue = watch("attentionScopeId");
  const diseaseIdValue = watch("diseaseId");
  const productIdValue = watch("productId");
  const dosageUnitIdValue = watch("dosageUnitId");
  const administrationRouteIdValue = watch("administrationRouteId");
  const visitTargetIdValue = watch("visitTargetId");
  const formulaTypeValue = watch("formulaType");
  const medicineAmountValue = watch("medicineAmount");

  const [disabled, setDisabled] = useState(false);
  const [formulaData, setFormulaData] = useState(null);
  const [cityList, setCityList] = useState([]);
  const [companyList, setCompanyList] = useState([]);
  const [attentionScopeList, setAttentionScopeList] = useState([]);
  const [diseaseList, setDiseaseList] = useState([]);
  const [dosageUnitList, setDosageUnitList] = useState([]);
  const [administrationRouteIdList, setAdministrationRouteIdList] = useState(
    []
  );
  const [visitTargetIdList, setVisitTargetIdList] = useState([]);
  const [formulaType, setFormulaType] = useState(FormulaType.MIPRES);

  const [loadForm, setLoadForm] = useState({
    stateProvinceListLoaded: false,
    productListLoaded: false,
    formulaDataLoaded: false,
    cityListLoaded: false,
    diseaseListLoaded: false,
    visitTargetIdListLoaded: false,
  });

  useEffect(() => {
    callApi(() => getGetFormula(selectedPrescription.impactId), setFormulaData, fnSetLoader);
    callApi(getDoctorList, setVisitTargetIdList);
  }, []);

  useEffect(() => {
    if (!openDlg) setProductBehavior(-1);
  }, [openDlg]);

  useEffect(() => {
    if (!loadForm.cityListLoaded && cityList.length > 0) {
      setValue("cityId", getCboValue(cityList, formulaData.cityId));
      setLoadForm({ ...loadForm, cityListLoaded: true });
      return;
    }
    if (!loadForm.diseaseListLoaded && diseaseList.length > 0) {
      setValue("diseaseId", getCboValue(diseaseList, formulaData.diseaseId));
      setLoadForm({ ...loadForm, diseaseListLoaded: true });
      return;
    }
    if (formulaData && !loadForm.visitTargetIdListLoaded && visitTargetIdList.length > 0) {
      setValue(
        "visitTargetId",
        getCboValue(visitTargetIdList, formulaData.visitTargetId)
      );
      setLoadForm({ ...loadForm, visitTargetIdListLoaded: true });

      return;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cityList, diseaseList, visitTargetIdList]);

  useEffect(() => {
    if (!loadForm.formulaDataLoaded && formulaData) {
      setCompanyList(formulaData.institutionList);
      setAttentionScopeList(formulaData.attentionScopeList);
      setDosageUnitList(formulaData.dosageUnitList);
      setAdministrationRouteIdList(formulaData.administrationRouteList);

      setValue(
        "medicineAmount",
        !formulaData.medicineAmount ? "" : formulaData.medicineAmount
      );
      setValue(
        "companyId",
        getCboValue(formulaData.institutionList, formulaData.companyId)
      );
      setValue(
        "attentionScopeId",
        getCboValue(
          formulaData.attentionScopeList,
          formulaData.attentionScopeId
        )
      );
      setFile(formulaData.file);

      if (!loadForm.stateProvinceListLoaded && stateProvinceList.length > 0) {
        setValue(
          "stateProvinceId",
          getCboValue(stateProvinceList, formulaData.stateProvinceId)
        );
        setLoadForm({ ...loadForm, stateProvinceListLoaded: true });
      }
      if (!loadForm.productListLoaded && productList.length > 0) {
        setValue("productId", getCboValue(productList, formulaData.productId));
        setProductBehavior(
          getProductBehavior(productList, formulaData.productId)
        );
        setLoadForm({ ...loadForm, productListLoaded: true });
      }

      setValue(
        "prescriptionType",
        getCboValue(prescriptionTypeList, formulaData.prescriptionType)
      );
      setValue(
        "expeditionDate",
        formulaData.expeditionDate ? dayjs(formulaData.expeditionDate) : null
      );
      setValue("code", !formulaData.code ? "" : formulaData.code);
      setValue("additionalGuidelines", !formulaData.additionalGuidelines ? "" : formulaData.additionalGuidelines);
      setValue(
        "durationTreatment",
        getCboValue(DurationTreatmentList(), formulaData.durationTreatment)
      );
      setValue(
        "formulaType",
        getCboValue(FormulaTypeList(), formulaData.formulaType)
      );
      //Dosage
      setValue(
        "dosageUnitId",
        getCboValue(
          formulaData.dosageUnitList,
          getDInfo(formulaData, "dosageUnitId")
        )
      );
      setValue("quantity", getDInfo(formulaData, "quantity"));
      setValue(
        "supplyFrequency",
        getCboValue(supplyFrequencyList, getDInfo(formulaData, "supplyFrequency"))
      );
      //setValue("supplyFrequency", getDInfo(formulaData, "supplyFrequency"));

      //setValue("infusionRate", getDInfo(formulaData, "infusionRate"));

      //setPrescriptionType(formulaData.prescriptionType);
      setFormulaType(formulaData.formulaType);

      setLoadForm({ ...loadForm, formulaDataLoaded: true });
      return;
    }
  }, [formulaData]);

  useEffect(() => {
    setValue("cityId", null);
    if (!stateProvinceIdValue || !stateProvinceIdValue.value) {
      setCityList([]);
      return;
    }
    callApi(
      () => getCitiesByStateProvinceId(stateProvinceIdValue.value),
      setCityList
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stateProvinceIdValue]);

  const updatePharmaceuticalQuantity = () => {
    if(!productIdValue || !productIdValue.value || !medicineAmountValue){
      setValue("pharmaceuticalQuantity", 0);
      return;
    }

    const product = productList.find(x => x.id == productIdValue.value);

    setValue(
      "pharmaceuticalQuantity",
      product.pharmaceuticalQuantity * medicineAmountValue
    );
  };
  
  useEffect(() => updatePharmaceuticalQuantity(), [medicineAmountValue]);

  useEffect(() => {
    setValue("diseaseId", null);
    if (!productIdValue || !productIdValue.value) {
      setDiseaseList([]);
      return;
    }
    callApi(() => getDiseasesByProductId(productIdValue.value), setDiseaseList);
    
    var product = productList.find(x => x.id == productIdValue.value);

    updatePharmaceuticalQuantity();

    setValue(
      "administrationRouteId",
      getCboValue(
        formulaData.administrationRouteList,
        product.administrationRouteId
      )
    );
    setValue(
      "pharmaceuticalUnit",
      getCboValue(PharmaceuticalUnitList(), product.pharmaceuticalUnit)
    );
    setValue(
      "dosageUnitId",
      getCboValue(
        formulaData.dosageUnitList,
        product.dosageUnitId
      )
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productIdValue]);

  useEffect(() => {
    if (selectedPrescription.stateId >= PrescripStateEnum.radicacionEPS) {
      setDisabled(true);
    }
  }, [selectedPrescription]);

  const reloadFormulaData = () => {
    setLoadForm({
      ...loadForm,
      formulaDataLoaded: false,
      cityListLoaded: false,
      diseaseListLoaded: false,
      visitTargetIdListLoaded: false,
    });
    callApi(() => getGetFormula(selectedPrescription.impactId), setFormulaData);
  };

  const cboFormulaTypeChange = (name) => (event) => {
    setValue(name, event);
    setFormulaType(event ? event.value : null);
  };
  const cboPrescriptionTypeChange = (name) => (event) => {
    setValue(name, event);
    //setPrescriptionType(event ? event.value : null);
  };
  const cboHandleChange = (name) => (event) => {
    setValue(name, event);
  };
  const cboProductChange = (name) => (event) => {
    setValue(name, event);
    setProductBehavior(getProductBehavior(productList, event.value));
  };
  const dtpHandleChange = (name) => (e) => {
    setValue(name, e);
  };

  const onSubmit = async (d, e) => {
    const obj = {
      id: formulaData.id,
      impactId: formulaData.impactId,
      productId: Number(d.productId.value),
      diseaseId: Number(d.diseaseId.value),
      cityId: Number(d.cityId.value),
      prescriptionType: Number(d.prescriptionType.value),
      expeditionDate: dayjs(d.expeditionDate).format("YYYY-MM-DDTHH:mm:ss"),
      visitTargetId: Number(d.visitTargetId.value),
      companyId: Number(d.companyId.value),
      durationTreatment: Number(d.durationTreatment.value),
      administrationRouteId: Number(d.administrationRouteId.value),
      medicineAmount: Number(d.medicineAmount),
      pharmaceuticalUnit: Number(d.pharmaceuticalUnit.value),
      formulaType: Number(d.formulaType.value),
      //Dosage
      dosageId: Number(getDInfo(formulaData, "id")),
      dosageUnitId: Number(d.dosageUnitId.value),
      quantity: Number(d.quantity),
      //supplyFrequency: Number(d.supplyFrequency),
      
      //infusionRate: Number(d.infusionRate),
      additionalGuidelines: d.additionalGuidelines,
    };

    if (formulaType == FormulaType.MIPRES) {
      obj.code = d.code;
      obj.attentionScopeId = Number(d.attentionScopeId.value);
    } else {
      obj.code = null;
      obj.attentionScopeId = null;
    }

    obj.supplyFrequency = d.supplyFrequency && d.supplyFrequency.value ? Number(d.supplyFrequency.value) : null;

    await callApiAsync(
      () => postSaveFormula(obj),
      (data) => {
        NotificationManager.success(msgSuccess);
        setFormulaData(data);
        setFile(data.file);
        dispatch(setFormFormulaDone(data.expeditionDate));
        if (
          selectedPrescription.stateId == PrescripStateEnum.formulatedWithErrors
        ) {
          dispatch(setPrescriptionState(PrescripStateEnum.formulated));
        }
        handleRequestClose();
      },
      fnSetLoader
    );
  };

  const [file, setFile] = useState("");
  
  return (
    <div>
      <ApproveFormula
        formulaData={formulaData}
        reloadFormulaData={reloadFormulaData}
      />
      <div className="col-xl-12 col-lg-12 col-md-12 col-12">
        <FormProvider {...methods}>
          <form className="row" noValidate autoComplete="off">
            <Grid container spacing={2}>
              <Grid item xs={12} md={12} lg={6}>
                <FormSelectAutoComplete
                  name="formulaType"
                  label={<IntlMessages id="formula.formulaType" />}
                  options={FormulaTypeList()}
                  value={watch("formulaType")}
                  onChange={cboFormulaTypeChange("formulaType")}
                  margin="normal"
                  disabled={disabled}
                  required={true}
                  errorobj={errors}
                />
              </Grid>            
              <Grid item xs={12} md={12} lg={6}>
                <FormSelectAutoComplete
                  name="prescriptionType"
                  label={<IntlMessages id="formula.prescriptionType" />}
                  options={prescriptionTypeList}
                  value={watch("prescriptionType")}
                  onChange={cboPrescriptionTypeChange("prescriptionType")}
                  margin="normal"
                  disabled={disabled}
                  required={true}
                  errorobj={errors}
                />
              </Grid>
              <Grid item xs={12} md={12} lg={6}>
                <FormSelectAutoComplete
                  name="stateProvinceId"
                  label={<IntlMessages id="core.stateProvince" />}
                  options={stateProvinceList}
                  value={stateProvinceIdValue}
                  onChange={cboHandleChange("stateProvinceId")}
                  margin="normal"
                  disabled={disabled}
                  required={true}
                  errorobj={errors}
                />
              </Grid>
              <Grid item xs={12} md={12} lg={6}>
                <FormSelectAutoComplete
                  name="cityId"
                  label={<IntlMessages id="core.city" />}
                  options={cityList}
                  value={cityIdValue}
                  onChange={cboHandleChange("cityId")}
                  margin="normal"
                  disabled={disabled}
                  required={true}
                  errorobj={errors}
                />
              </Grid>
              <Grid item xs={12} md={12} lg={6}>
                <FormDatePicker
                  name="expeditionDate"
                  label={<IntlMessages id="core.expeditionDate" />}
                  format="YYYY-MM-DD"
                  disableFuture={true}
                  onChange={dtpHandleChange("expeditionDate")}
                  value={watch("expeditionDate")}
                  margin="normal"
                  disabled={disabled}
                  required={true}
                  errorobj={errors}
                />
              </Grid>
              {formulaType == FormulaType.MIPRES && (
                <Grid item xs={12} md={12} lg={6}>
                  <FormInput
                    name="code"
                    label={<IntlMessages id="formula.FormulaCode" />}
                    margin="normal"
                    disabled={disabled}
                    required={true}
                    errorobj={errors}
                  />
                </Grid>
              )}
              <Grid item xs={12} md={12} lg={6}>
                <FormSelectAutoComplete
                  name="companyId"
                  label={<IntlMessages id="core.institution" />}
                  options={companyList}
                  value={companyIdValue}
                  onChange={cboHandleChange("companyId")}
                  margin="normal"
                  disabled={disabled}
                  required={true}
                  errorobj={errors}
                />
              </Grid>
              <Grid item xs={12} md={12} lg={6}>
                <FormSelectAutoComplete
                  name="visitTargetId"
                  label={<IntlMessages id="core.doctor" />}
                  options={visitTargetIdList}
                  value={visitTargetIdValue}
                  onChange={cboHandleChange("visitTargetId")}
                  margin="normal"
                  disabled={disabled}
                  required={true}
                  errorobj={errors}
                />
              </Grid>
              {formulaType == FormulaType.MIPRES && (
                <Grid item xs={12} md={12} lg={6}>
                  <FormSelectAutoComplete
                    name="attentionScopeId"
                    label={<IntlMessages id="formula.attentionScope" />}
                    options={attentionScopeList}
                    value={attentionScopeIdValue}
                    onChange={cboHandleChange("attentionScopeId")}
                    margin="normal"
                    disabled={disabled}
                    required={true}
                    errorobj={errors}
                  />
                </Grid>
              )}
              <Grid item xs={12} md={12} lg={6}>
                <FormSelectAutoComplete
                  name="diseaseId"
                  label={<IntlMessages id="formula.diagnostic" />}
                  options={diseaseList}
                  value={diseaseIdValue}
                  onChange={cboHandleChange("diseaseId")}
                  margin="normal"
                  disabled={disabled}
                  required={true}
                  errorobj={errors}
                />
              </Grid>
              <Grid item xs={12} md={12} lg={6}>
                <FormSelectAutoComplete
                  name="productId"
                  label={<IntlMessages id="formula.product" />}
                  options={productList}
                  value={productIdValue}
                  onChange={cboProductChange("productId")}
                  margin="normal"
                  disabled={disabled}
                  required={true}
                  errorobj={errors}
                />
              </Grid>
              <Grid item xs={12} md={12} lg={6}>
                <FormInput
                  name="medicineAmount"
                  label={<IntlMessages id="formula.medicineAmount" />}
                  margin="normal"
                  disabled={disabled}
                  required={true}
                  errorobj={errors}
                />
              </Grid>
              <Grid item xs={12} md={12} lg={6}>
                <FormInput
                  name="quantity"
                  label={<IntlMessages id="core.dosage" />}
                  margin="normal"
                  disabled={disabled}
                  required={true}
                  errorobj={errors}
                />
              </Grid>
              <Grid item xs={12} md={12} lg={6}>
                <FormSelectAutoComplete
                  name="dosageUnitId"
                  label={<IntlMessages id="formula.dosageUnit" />}
                  options={dosageUnitList}
                  // value={dosageUnitIdValue}
                  // onChange={cboHandleChange("dosageUnitId")}
                  margin="normal"
                  disabled={true}
                  required={true}
                  errorobj={errors}
                />
              </Grid>
              {/*}
              {(productBehavior == -1 ||
                productBehavior == ProductBehavior.remodulin || productBehavior == ProductBehavior.dacepton) && (
                <Grid item xs={12} md={12} lg={6}>
                  <FormInput
                    name="infusionRate"
                    label={<IntlMessages id="formula.infusionRate" />}
                    margin="normal"
                    disabled={disabled}
                    required={true}
                    errorobj={errors}
                  />
                </Grid>
              )}  
              {*/}            
              { (productBehavior == -1 || (formulaTypeValue.value == FormulaType.MIPRES &&
                productBehavior != ProductBehavior.remodulin &&
                productBehavior != ProductBehavior.dacepton)) &&
                <Grid item xs={12} md={12} lg={6}>
                  <FormSelectAutoComplete
                  name="supplyFrequency"
                  label={<IntlMessages id="formula.supplyFrequency" />}
                  options={supplyFrequencyList}
                  margin="normal"
                  disabled={disabled}
                  required={true}
                  errorobj={errors}
                />                 
                </Grid>
              }              
              <Grid item xs={12} md={12} lg={6}>
                <FormSelectAutoComplete
                  name="durationTreatment"
                  label={<IntlMessages id="formula.durationTreatment" />}
                  options={DurationTreatmentList()}
                  value={watch("durationTreatment")}
                  onChange={cboHandleChange("durationTreatment")}
                  margin="normal"
                  disabled={disabled}
                  required={true}
                  errorobj={errors}
                />
              </Grid>
              <Grid item xs={12} md={12} lg={6}>
                <FormInput
                  name="pharmaceuticalQuantity"
                  label={<IntlMessages id="formula.pharmaceuticalQuantity" />}
                  margin="normal"
                  disabled={true}
                  required={true}
                  errorobj={errors}
                />
              </Grid>
              <Grid item xs={12} md={12} lg={6}>
                <FormSelectAutoComplete
                  name="pharmaceuticalUnit"
                  label={<IntlMessages id="formula.pharmaceuticalUnit" />}
                  options={PharmaceuticalUnitList()}
                  value={watch("pharmaceuticalUnit")}
                  onChange={cboHandleChange("pharmaceuticalUnit")}
                  margin="normal"
                  disabled={true}
                  required={true}
                  errorobj={errors}
                />
              </Grid>
              <Grid item xs={12} md={12} lg={6}>
                <FormSelectAutoComplete
                  name="administrationRouteId"
                  label={<IntlMessages id="formula.administrationRoute" />}
                  options={administrationRouteIdList}
                  value={administrationRouteIdValue}
                  onChange={cboHandleChange("administrationRouteId")}
                  margin="normal"
                  disabled={true}
                  required={true}
                  errorobj={errors}
                />
              </Grid>
              <Grid item xs={12} md={12} lg={6}>
                  <FormInput
                    name="additionalGuidelines"
                    label={<IntlMessages id="formula.additionalGuidelines" />}
                    margin="normal"
                    multiline
                    rows={3}
                    disabled={disabled}
                    required={false}
                    errorobj={errors}
                  />
                </Grid>
            </Grid>
          </form>
        </FormProvider>
        <div className="col-lg-12 col-md-12 col-xs-12 text-center mt-4">
          <ButtonAsync onClick={handleSubmit(onSubmit)}
            variant="contained"
            color="primary"
            hidden={disabled}
          >
            {<IntlMessages id="core.save" />}
          </ButtonAsync>
        </div>
        {(!disabled || file) && formulaData &&
          <CardFileUpload 
            disabled = { disabled }
            file={file} 
            setFile={setFile}
            actionApi={postFormulaFile}
            actionApiInfo={{ impactId: formulaData.impactId }}
          />
        }
      </div>
    </div>
  );
};

export default FormulaForm;
