import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useForm, FormProvider } from "react-hook-form";
import { NotificationManager } from "react-notifications";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers";
import * as dayjs from "dayjs";
import IntlMessages, { IntlGetText } from "util/IntlMessages";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle
} from "@material-ui/core";
import InfoCard from "components/InfoCard/index";
import FormSelectAutoComplete from "components/Form/Controls/select-autocomplete";
import FormInput from "components/Form/Controls/input";
import FormCheckbox from "components/Form/Controls/checkbox";
import FormDatePicker from "components/Form/Controls/datepicker";
import ButtonAsync from "components/Form/Controls/ButtonAsync";
import CardFileUpload from "components/Form/CardFileUpload";
import {
  setClinicalHistoryState,
  callStartImpact,
  sethistoryData,
  setReloadDosage
} from "actions/Impact";
import {
  callApi,
  callApiAsync,
  getClinicHistoryList,
  getClinicHistoryDetail,
  postClinicHistory,
  postClinicHistoryFile
} from "api/apiList";
import { FolderType, ControlTypeEnum } from "constants/Enums";
import { isDirectTracing } from "app/routes/psp/services/userService";

const FormDinamic = ({
  historyData,
  variableDetail,
  impactId,
  variableGroupValue,
  setVariableDetail,
  readOnly,
  setvariableGroups,
  variableGroups,
  clearFile
}) => {
  const dispatch = useDispatch();
  const { clinicalHistoryStateDone, fileCHVariableGroupId } = useSelector(
    ({ impact }) => impact
  );

  const msgSuccess = IntlGetText("core.SuccessSave");
  const msgFileWarning = IntlGetText("core.fileNotFound");
  const msgAtLeastOneValidation = IntlGetText(
    "clinicHistory.atLeastOneValidation"
  );

  const data = variableDetail.data;
  const file = variableDetail.file;

  const getCboValue = (items, id) => {
    const result = items.find((x) => x.id + "" === id);
    return result ? { label: result.name, value: result.id } : "";
  };

  let defaultValues = {};
  let schema = {};

  data.map((item) => {
    if (item.controlType === ControlTypeEnum.select) {
      defaultValues["item" + item.id] = getCboValue(
        item.selectOptions,
        item.value
      );
      // schema["item" + item.id] = yup
      //   .object()
      //   .required(<IntlMessages id="core.fieldRequired" />)
      //   .nullable();
    } else if (item.controlType === ControlTypeEnum.text) {
      defaultValues["item" + item.id] =
        item.value && item.value !== "null" ? item.value : "";
      // schema["item" + item.id] = yup
      //   .string()
      //   .required(<IntlMessages id="core.fieldRequired" />);
    } else if (item.controlType === ControlTypeEnum.dateTime) {
      defaultValues["item" + item.id] = item.value ? dayjs(item.value) : null;
      // schema["item" + item.id] = yup
      //   .date()
      //   .nullable(true)
      //   .typeError(IntlGetText("yup.InvalidDate"))
      //   .required(<IntlMessages id="core.fieldRequired" />);
    } else if (item.controlType === ControlTypeEnum.number) {
      defaultValues["item" + item.id] =
        item.value && item.value !== "null" ? item.value : "";
    } else {
      defaultValues["item" + item.id] = item.value;
      // schema["item" + item.id] = yup
      //   .string()
      //   .required(<IntlMessages id="core.fieldRequired" />);
    }
  });

  const methods = useForm({
    defaultValues,
    resolver: yupResolver(yup.object().shape(schema))
  });
  const { handleSubmit, watch, setValue, reset, control, errors } = methods;

  const onSubmit = async (d, e) => {
    var objRes = [];
    var objKeys = Object.keys(d);
    var atLeastOne = false;

    objKeys.map((item, index) => {
      const itemId = item.substr(4);
      let itemValue = "";

      const itemData = data.find((x) => x.id + "" === itemId);

      if (!atLeastOne && d[item]) atLeastOne = true;

      switch (itemData.controlType) {
        case ControlTypeEnum.text:
          itemValue = d[item] + "";
          break;
        case ControlTypeEnum.number:
          itemValue = d[item] + "";
          break;
        case ControlTypeEnum.dateTime:
          itemValue = !d[item]
            ? null
            : dayjs(d[item]).format("YYYY-MM-DDTHH:mm:ss") + "";
          break;
        case ControlTypeEnum.checkbox:
          itemValue = d[item] + "";
          break;
        case ControlTypeEnum.select:
          itemValue =
            !d[item].value && d[item].value !== 0 ? null : d[item].value + "";
          break;

        default:
          break;
      }
      objRes.push({
        controlType: itemData.controlType,
        id: itemData.id,
        value: itemValue,
        paraclinicId: itemData.paraclinicId
      });
    });

    let result = {
      impactId,
      variableGroupId: variableGroupValue.value,
      items: [...objRes]
    };

    if (!atLeastOne) {
      NotificationManager.warning(msgAtLeastOneValidation);
      return;
    }

    await callApiAsync(
      () => postClinicHistory(result),
      async (data) => {
        if (!historyData) {
          dispatch(sethistoryData(true));
        }
        dispatch(setReloadDosage(true));
        NotificationManager.success(msgSuccess);
        setVariableDetail({ data, file: file });
      }
    );
  };

  const chkHandleChange = (name) => (e) => setValue(name, e.target.checked);
  const cboHandleChange = (name) => (e) => {
    setValue(name, e);
  };
  const dtpHandleChange = (name) => (e) => {
    setValue(name, e);
  };

  const updateVariableGroups = (variableGroupId, file) => {
    let index = variableGroups.findIndex((x) => x.id === variableGroupId);
    let data = [...variableGroups];
    data[index] = { ...data[index], file };

    setvariableGroups(data);
  };

  const showCardFile = () => {
    if (file) return true;
    if (!file && readOnly) return false;
    return true;
  };

  return (
    <React.Fragment>
      <FormProvider {...methods}>
        <form className="row mt-2" noValidate autoComplete="off">
          {data.map((item, index) => {
            return (
              <React.Fragment key={item.id}>
                {item.controlType === ControlTypeEnum.text && (
                  <FormInput
                    name={"item" + item.id}
                    label={item.name}
                    defaultValue={defaultValues["item" + item.id]}
                    disabled={readOnly}
                    //required={true}
                    errorobj={errors}
                    className="mt-2 mb-2"
                  />
                )}

                {item.controlType === ControlTypeEnum.dateTime && (
                  <FormDatePicker
                    name={"item" + item.id}
                    label={item.name}
                    defaultValue={defaultValues["item" + item.id]}
                    format="YYYY-MM-DD"
                    onChange={dtpHandleChange("item" + item.id)}
                    value={watch("item" + item.id)}
                    disabled={readOnly}
                    //required={true}
                    errorobj={errors}
                    className="mt-2 mb-2"
                  />
                )}

                {item.controlType === ControlTypeEnum.checkbox && (
                  <FormCheckbox
                    name={"item" + item.id}
                    label={item.name}
                    defaultValue={defaultValues["item" + item.id] === "true"}
                    value={watch("item" + item.id)}
                    onChange={chkHandleChange("item" + item.id)}
                    //className="mt-3"
                    disabled={readOnly}
                  />
                )}

                {item.controlType === ControlTypeEnum.select && (
                  <FormSelectAutoComplete
                    name={"item" + item.id}
                    label={item.name}
                    options={item.selectOptions}
                    defaultValue={defaultValues["item" + item.id]}
                    value={watch("item" + item.id)}
                    onChange={cboHandleChange("item" + item.id)}
                    disabled={readOnly}
                    //required={true}
                    errorobj={errors}
                    className="mt-2 mb-2"
                  />
                )}

                {item.controlType === ControlTypeEnum.number && (
                  <FormInput
                    name={"item" + item.id}
                    type="number"
                    label={item.name}
                    defaultValue={defaultValues["item" + item.id]}
                    disabled={readOnly}
                    errorobj={errors}
                    className="mt-2 mb-2"
                  />
                )}
              </React.Fragment>
            );
          })}
        </form>
        {!readOnly && data.length > 0 && (
          <div className="col-xl-12 col-lg-12 col-md-12 col-12 text-center mt-4">
            <ButtonAsync
              onClick={handleSubmit(onSubmit)}
              variant="contained"
              color="primary"
            >
              {<IntlMessages id="core.save" />}
            </ButtonAsync>
          </div>
        )}

        {!variableGroupValue && <div style={{ height: 100 }}></div>}
      </FormProvider>

      {variableGroupValue && showCardFile() && (
        <CardFileUpload
          disabled={readOnly}
          file={file}
          setFile={(file) => {
            setVariableDetail({ data: variableDetail.data, file: file });
            updateVariableGroups(variableGroupValue.value, file);

            // if(variableGroupValue.value == fileCHVariableGroupId
            //   && !clinicalHistoryStateDone){
            if (!clinicalHistoryStateDone) {
              dispatch(setClinicalHistoryState(true));
            }
          }}
          actionApi={postClinicHistoryFile}
          actionApiInfo={{
            impactId,
            variableGroupId: variableGroupValue.value
          }}
          reset={clearFile}
        />
      )}
    </React.Fragment>
  );
};

const ClinicalHistory = () => {
  const dispatch = useDispatch();
  const { selectedMenuItem, selectedPrescription } = useSelector(
    ({ management }) => management
  );
  const isDirectTracingConfig = isDirectTracing();
  const {
    impactStartDate,
    consentSigned,
    isCIRequired,
    historyData
  } = useSelector(({ impact }) => impact);

  const [open, setOpen] = useState(false);
  const [variableGroups, setvariableGroups] = useState([]);
  const [variableDetail, setVariableDetail] = useState({ data: [], file: "" });
  const [clearFile, setClearFile] = useState(false);

  const msgStartImpactRequired = IntlGetText("impact.startImpactRequired");
  const msgConsentRequired = IntlGetText("impact.consentRequired");

  const readOnly = selectedMenuItem == FolderType.inProcess;

  const handleClickOpen = () => {
    if (!impactStartDate) {
      dispatch(callStartImpact(true));
      return;
    }
    if (isCIRequired && !consentSigned) {
      NotificationManager.warning(msgConsentRequired);
      return;
    }
    setOpen(true);
  };
  const handleRequestClose = () => {
    setVariableDetail({ data: [], file: "" });
    setOpen(false);
  };

  const defaultValues = {
    cboVariableGroup: ""
  };

  const methods = useForm({ defaultValues });
  const { setValue, watch } = methods;
  const variableGroupValue = watch("cboVariableGroup");

  const loadDetail = (impactId, variableGroupId) =>
    callApi(
      () => getClinicHistoryDetail(impactId, variableGroupId, selectedMenuItem),
      (data) =>
        setVariableDetail({
          data,
          file: variableGroups.find((x) => x.id === variableGroupId).file
        })
    );

  const vgHandleChange = (e) => {
    setValue("cboVariableGroup", e);
    if (e && e.value) {
      // const detail = variableGroupDetail.filter((x)=>x.variableGroupId === e.value);
      // setVariableDetail(detail);
      setClearFile(true);
      setTimeout(() => setClearFile(false), 500);
      loadDetail(selectedPrescription.impactId, e.value);
    } else {
      setVariableDetail({ data: [], file: "" });
    }
  };

  const loadClinicHistoryList = () => {
    callApi(
      () => getClinicHistoryList(selectedPrescription.impactId),
      (data) => {
        setvariableGroups(
          readOnly ? data.filter((x) => x.file || x.hasData) : data
        );
      }
    );
  };

  useEffect(() => {
    loadClinicHistoryList();
    //setvariableGroups(variableGroup);
  }, []);

  useEffect(() => loadClinicHistoryList(), [selectedPrescription.id]);

  return (
    <div>
      <InfoCard
        data={{
          icon: "city-alt",
          title: isDirectTracingConfig ? "Campos" : "Historia",
          subTitle: isDirectTracingConfig ? "personalizados" : "Clinica",
          color: historyData ? "#20c997" : "#E53935"
        }}
        onClickBox={handleClickOpen}
      />
      <Dialog
        fullWidth={true}
        maxWidth="md"
        open={open}
        disableBackdropClick={true}
        onClose={handleRequestClose}
      >
        <DialogTitle>
          {isDirectTracingConfig ? (
            <IntlMessages id="core.CustomFields" />
          ) : (
            <IntlMessages id="core.clinicalHistory" />
          )}
        </DialogTitle>
        <DialogContent className="new-patient-dialog-content">
          <FormProvider {...methods}>
            <form className="row" noValidate autoComplete="off">
              <FormSelectAutoComplete
                label={
                  <IntlMessages
                    id={
                      isDirectTracingConfig
                        ? "core.SelectOne"
                        : "core.variableGroup"
                    }
                  />
                }
                options={variableGroups}
                required={false}
                name="cboVariableGroup"
                fullWidth
                value={variableGroupValue}
                onChange={vgHandleChange}
              />
            </form>
          </FormProvider>
          <FormDinamic
            historyData={historyData}
            variableDetail={variableDetail}
            impactId={selectedPrescription.impactId}
            variableGroupValue={variableGroupValue}
            setVariableDetail={setVariableDetail}
            // setVariableDetail={(dataDetail) => {
            //   if (!clinicalHistoryStateDone) {
            //     dispatch(setClinicalHistoryState(true));
            //   }
            //   setVariableDetail(dataDetail);
            // }}
            readOnly={readOnly}
            setvariableGroups={setvariableGroups}
            variableGroups={variableGroups}
            clearFile={clearFile}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleRequestClose} color="secondary">
            {<IntlMessages id="core.close" />}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default ClinicalHistory;
