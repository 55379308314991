import React from "react";
import * as dayjs from "dayjs";
import IntlMessages, { IntlGetText } from "util/IntlMessages";
import { Button } from "@material-ui/core";
import Table, { getTextLabels, defaultCol, dateColumn } from "components/Form/Table";
import CardBox from "components/CardBox/index";
import { ProductBehavior } from "constants/Enums";

const TableAM = ({
  data,
  handleOpenCRU,
  setCurrentRowObj,
  currentImpact,
  dosageInfo,
  showAddButton,
  readOnly,
  pendingInfusionRate,
}) => {

  const options = {
    filter: false,
    filterType: "dropdown",
    download: false,
    print: false,
    viewColumns: false,
    selectableRows: "none",
    search: false,
    textLabels: getTextLabels(),
  };

  const handleClick = (obj) => {
    handleOpenCRU();
    setCurrentRowObj(
      !obj ? null : obj
    );
  };
  const getRowObj = (tableMeta) => tableMeta.tableData[tableMeta.rowIndex];

  const isRemodulin = () => (dosageInfo && (dosageInfo.productBehaviorId === ProductBehavior.remodulin || dosageInfo.productBehaviorId === ProductBehavior.dacepton));

  const columns = [
    defaultCol("formulaCode", "formula.FormulaCode"),
    dateColumn("formulaStartDate", "core.expeditionDate"),
    defaultCol("durationTreatment", "formula.durationTreatment"),
    {
      name: "startDate",
      label: IntlGetText("core.startDate"),
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return value ? dayjs(value).format("YYYY/MM/DD") : "";
        },
      },
    },
    {
      name: "endDate",
      label: IntlGetText("core.endDate"),
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return value ? dayjs(value).format("YYYY/MM/DD") : "";
        },
      },
    },
    {
      name: "",
      label: IntlGetText("core.dosage"),
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          var obj = getRowObj(tableMeta);
          return obj.quantity +" "+ obj.dosageUnit;
        },
      },
    },
    {
      name: "",
      label: IntlGetText(isRemodulin() ? "formula.infusionRate": "formula.supplyFrequency"),
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          var obj = getRowObj(tableMeta);
          const colVal = isRemodulin() ? obj.infusionRate : obj.supplyFrequency;
          return (
            <div className="text-center">
              {!colVal ? "" : colVal}
            </div>
          );
        } 
      },
    },
    {
      name: "actions",
      label: IntlGetText("core.actions"),
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) => {          
          var rowObj = getRowObj(tableMeta);
          if (!pendingInfusionRate && (rowObj.impactId != currentImpact || readOnly)) {
            return (
              <div>
                <Button
                  onClick={() => handleClick(rowObj)}
                  variant="contained"
                  className="jr-btn jr-btn-xs"
                >
                  <i className="zmdi zmdi-eye"></i>
                </Button>
              </div>
            );
          }

          return (
            <div>
              <Button
                onClick={() => handleClick(rowObj)}
                variant="contained"
                className="jr-btn jr-btn-xs"
              >
                <i className="zmdi zmdi-edit"></i>
              </Button>
            </div>
          );
        },
      },
    },
  ];

  return (
    <div className="row">
      <CardBox styleName="col-12" cardStyle="p-0 border-dialog-card">
        <Table
          className="border-dialog-card"
          data={data}
          columns={columns}
          options={options}
        />
      </CardBox>
      {!readOnly && showAddButton && (
        <div className="col-lg-12 col-md-12 col-xs-12 mt-4 text-center">
          <Button
            onClick={() => handleClick(null)}
            variant="contained"
            color="primary"
          >
            {<IntlMessages id="core.add" />}
          </Button>
        </div>
      )}
    </div>
  );
};

export default TableAM;
