import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { NotificationManager } from "react-notifications";
import IntlMessages, { IntlGetText } from "util/IntlMessages";
import InfoCard from "components/InfoCard/index";
import Dialog from "./Dialog";
import { callActionApi, getDosageInfo } from "api/apiList";
import { callStartImpact, setReloadDosage } from "actions/Impact";
import { ProductBehavior } from "constants/Enums";

const existItemsInImpact = (impactId, dataList) =>
  dataList.find((x) => x.impactId == impactId) != undefined;

const Dosage = () => {
  const dispatch = useDispatch();
  const { selectedPrescription } = useSelector(({ management }) => management);
  const { impactStartDate, consentSigned, isCIRequired, reloadDosageInfo } = useSelector(({ impact }) => impact);

  const msgConsentRequired = IntlGetText("impact.consentRequired");
  const msgWeightRequired = IntlGetText("impact.weightRequired");

  const [open, setOpen] = useState(false);
  const [dosageInfo, setDosageInfo] = useState(null);

  const handleClickOpen = () => {
    if (!impactStartDate) {
      dispatch(callStartImpact(true));
      return;
    }
    if (isCIRequired &&  !consentSigned) {
      NotificationManager.warning(msgConsentRequired);
      return;
    }
    
    const isRemodulin = (!dosageInfo 
                        || dosageInfo.productBehaviorId === ProductBehavior.remodulin 
                        || dosageInfo.productBehaviorId === ProductBehavior.dacepton );
    if (isRemodulin && dosageInfo && !dosageInfo.patientWeight ) {
      NotificationManager.warning(msgWeightRequired);
      return;
    }
    setOpen(true);
  };

  const callApi = (apiFunc, setData) => {
    callActionApi(
      apiFunc,
      setData,
      (error) => {
        if (typeof error === "string") NotificationManager.error(error);
      } //, (data) => dispatch(setLoader(data))
    );
  };

  const loadDosage = () => {
    callApi(
      () => getDosageInfo(selectedPrescription.id),
      (data) => {
        setDosageInfo(data);
      }
    );
  };

  useEffect(() => {
    loadDosage();
  }, []);
  
  useEffect(() => {
    if(reloadDosageInfo){
      loadDosage();
      dispatch(setReloadDosage(false));
    }
  }, [reloadDosageInfo]);

  const handleRequestClose = () => setOpen(false);

  return (
    <div>
      <InfoCard
        data={{
          icon: "drink",
          title: IntlGetText("dosage.cardTitle"),
          subTitle: IntlGetText("dosage.cardSubTitle"),
          color: existItemsInImpact(
            selectedPrescription.impactId,
            !dosageInfo || !dosageInfo.dosageList ? [] : dosageInfo.dosageList
          )
            ? "#20c997"
            : "#757575",
        }}
        onClickBox={handleClickOpen}
      />

      <Dialog
        open={open}
        handleRequestClose={handleRequestClose}
        loadDosage={loadDosage}
        disableBackdropClick={true}
        dosageInfo={dosageInfo}
      />
    </div>
  );
};

export default Dosage;
