import React from "react";
import { useDispatch } from "react-redux";
import IntlMessages from "util/IntlMessages";
import { Button } from "@material-ui/core";
import upload from "services/blobService";
import { setLoader } from "actions/Base";
import heic2any from "heic2any";

const FileUpload = ({ id, setResult }) => {
  const dispatch = useDispatch();
  const fnSetLoader = (data) => dispatch(setLoader(data));

  const finalId = id ? id : new Date().getMilliseconds();
  const handleFileChange = async (event) => {
    fnSetLoader(true);
    const file = await convertFileIfHEIC(event.target.files[0]);
    await upload(file, async (fileName) => {
      setResult(fileName);
      fnSetLoader(false);
    });
  };

  return (
    <label htmlFor={finalId}>
      <input
        id={finalId}
        type="file"
        name="file"
        style={{ display: "none" }}
        onChange={handleFileChange}
      />
      <Button color="secondary" aria-label="add" component="span">
        <i className="zmdi zmdi-upload mr-2"></i>{" "}
        <IntlMessages id="core.uploadFile" />
      </Button>
    </label>
  );
};

async function convertFileIfHEIC(file) {
  const isHEIC = file && file.name.toLowerCase().endsWith(".heic");

  if (isHEIC) {
    try {
      const convertedBlobs = await heic2any({
        blob: file,
        toType: "image/jpeg",
        quality: 0.8,
      });

      const convertedBlob = Array.isArray(convertedBlobs)
        ? convertedBlobs[0]
        : convertedBlobs;

      if (!convertedBlob) {
        throw new Error("Conversión fallida: el Blob convertido es inválido.");
      }

      const newFile = new File(
        [convertedBlob],
        file.name.replace(/\.\w+$/, ".jpg"),
        {
          type: "image/jpeg",
        }
      );

      return newFile;
    } catch (error) {
      console.error("Error during conversion:", error);
      throw new Error("Error during HEIC to JPEG conversion.");
    }
  } else {
    return file;
  }
}

export const FileUploadWithOriginalName = ({ children, ...props }) => {
  const dispatch = useDispatch();
  const fnSetLoader = (data) => dispatch(setLoader(data));

  const { id, setResult } = props;
  const finalId = id ? id : new Date().getMilliseconds();
  const handleFileChange = async (event) => {
    fnSetLoader(true);
    const file = await convertFileIfHEIC(event.target.files[0]);

    await upload(file, async (fileName, originalName) => {
      setResult({ fileName, originalName });
      fnSetLoader(false);
    });
  };

  return (
    <label htmlFor={finalId}>
      <input
        id={finalId}
        type="file"
        name="file"
        style={{ display: "none" }}
        onChange={handleFileChange}
      />
      {children}
    </label>
  );
};

export default FileUpload;
