import React, { useEffect } from "react";
import { useForm, FormProvider } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers";
import * as dayjs from "dayjs";
import IntlMessages, { IntlGetText } from "util/IntlMessages";
import { 
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid
} from "@material-ui/core";
import FormInput from "components/Form/Controls/input";
import FormSelectAutoComplete from "components/Form/Controls/select-autocomplete";
import FormDatePicker from "components/Form/Controls/datepicker";
import ButtonAsync from "components/Form/Controls/ButtonAsync";
import { 
  ProductBehavior, 
  FormulaType,
  SupplyFrequencyList
} from "constants/Enums";


const getCboValue = (items, id) => {
  if(typeof(id) == "object"){
    return id;
  }
  const result = items.find((x) => x.id === id);
  return result ? { label: result.name, value: result.id } : "";
};

const FormCRU =({ rowObj, impactId, openCRU, handleRequestClose, handleCreateUpdate, dosageInfo, readOnly, pendingInfusionRate })=>{
    const supplyFrequencyList = SupplyFrequencyList();
    
    const defaultValues = {
      dosageUnitId: "",
      supplyFrequency: "",
      quantity: "",
      infusionRate: "",
      startDate: null,
      patientWeight: "0",
      medicineConcentration: "0"
    };
  
    const validationSchema = {
      dosageUnitId: yup .object()
        .shape({ value: yup.string().required() })
        .required(<IntlMessages id="core.fieldRequired" />)
        .nullable(),
      quantity: yup
        .number()
        .typeError(IntlGetText("yup.numericValidation"))
        .min(1, IntlGetText("yup.numericValidation"))
        .max(50000, IntlGetText("yup.invalidInput"))
        .required(<IntlMessages id="core.fieldRequired" />),
      infusionRate: yup.number().when("dosageUnitId", {
        is: dosageUnitId => !dosageInfo || dosageInfo.productBehaviorId === ProductBehavior.remodulin || dosageInfo.productBehaviorId === ProductBehavior.dacepton,
        then:  yup.number()
          .typeError(IntlGetText("yup.numericValidation"))
          .min(0, IntlGetText("yup.numericValidation"))
          .max(dosageInfo.productBehaviorId === ProductBehavior.remodulin ? 1 : 50000, IntlGetText("yup.invalidInput"))
          .required(<IntlMessages id="core.fieldRequired" />)
        }),
      supplyFrequency: yup.object().when("dosageUnitId", {
        is: (dosageUnit) => (!dosageInfo || (dosageInfo.formulaType === FormulaType.MIPRES &&
          dosageInfo.productBehaviorId !== ProductBehavior.remodulin &&
          dosageInfo.productBehaviorId !== ProductBehavior.dacepton)),
        then: yup.object()
          .shape({ value: yup.string().required() })
          .required(<IntlMessages id="core.fieldRequired" />)
          .nullable()
        }),
      startDate: yup.date()
        .nullable(true)
        .typeError(IntlGetText("yup.InvalidDate"))
        .required(<IntlMessages id="core.fieldRequired" />),
    };
  
    const methods = useForm({ 
      defaultValues,
      resolver: yupResolver(yup.object().shape(validationSchema)),
    });
    const { handleSubmit, watch, setValue, errors } = methods;
    
    const quantityValue = watch("quantity");
    //const patientWeightValue = watch("patientWeight");

    const calculateInfusionRate = () =>{
      if(!pendingInfusionRate && rowObj && rowObj.impactId !== impactId)
        return;

      if(!dosageInfo || !dosageInfo.patientWeight)
        return;

      const weight = rowObj && rowObj.patientWeight ? rowObj.patientWeight : dosageInfo.patientWeight;
      let infusionRate = (Number(quantityValue) * Number(weight) * 0.00006) / dosageInfo.medicineConcentration;
      infusionRate = Number(infusionRate.toFixed(3));
      setValue("infusionRate",infusionRate);
    };

    useEffect(calculateInfusionRate,[quantityValue]);

    useEffect(()=>{
      if(openCRU){
        setTimeout(()=>loadData(rowObj),150);
      }
    },[openCRU]);

    const getDosageUnitList = (info) => !info ? [] : info.dosageUnitList;

    const getWeight = (rowObj, dosageInfo) => {
      if(rowObj && rowObj.patientWeight)
        return rowObj.patientWeight;
      else{
        if(!dosageInfo || !dosageInfo.patientWeight)
          return "";

        if(!rowObj || pendingInfusionRate)
          return dosageInfo.patientWeight;
      }
      return "";
    };
    const loadData = (rowObj) => {
        setValue("dosageUnitId", !rowObj ? (!dosageInfo ? "" : getCboValue(getDosageUnitList(dosageInfo), dosageInfo.dosageUnitId)) : getCboValue(getDosageUnitList(dosageInfo), rowObj.dosageUnitId));
        setValue("supplyFrequency", !rowObj || !rowObj.supplyFrequency ? null : getCboValue(supplyFrequencyList,rowObj.supplyFrequency));
        setValue("infusionRate", !rowObj || !rowObj.infusionRate ? "" : rowObj.infusionRate);
        setValue("quantity", !rowObj || !rowObj.quantity ? "" : rowObj.quantity);
        setValue("startDate", !rowObj || !rowObj.startDate ? null : dayjs(rowObj.startDate));
        
        setValue("medicineConcentration", !dosageInfo || !dosageInfo.medicineConcentration ? "0" : dosageInfo.medicineConcentration);
        //setValue("patientWeight", (!rowObj || !rowObj.patientWeight) ? ((!dosageInfo || !dosageInfo.patientWeight) ? "0" : dosageInfo.patientWeight) : rowObj.patientWeight
        setValue("patientWeight", getWeight(rowObj, dosageInfo));
    };

    const onSubmit = async (d, e) => {
      var obj = {
        ...rowObj,
        id: !rowObj ? 0 : rowObj.id,
        impactId: impactId,
        dosageUnitId: Number(d.dosageUnitId.value),
        supplyFrequency: d.supplyFrequency && d.supplyFrequency.value ? Number(d.supplyFrequency.value) : null,
        quantity: Number(d.quantity),
        infusionRate: Number(d.infusionRate),
        startDate:  dayjs(d.startDate).format("YYYY-MM-DDTHH:mm:ss"),
        patientWeight: Number(d.patientWeight),
      };
      await handleCreateUpdate(obj);
    };

    const disabled = pendingInfusionRate ? false : (rowObj && rowObj.impactId != impactId) || readOnly;

    return (
      <Dialog
        fullWidth={true}
        maxWidth="md"
        open={openCRU}
        disableBackdropClick={true}
        onClose={handleRequestClose}
      >
        <DialogTitle>
          <IntlMessages id={!rowObj ? "core.add" : "core.edit"} />
        </DialogTitle>
        <DialogContent>
          <div className="col-xl-12 col-lg-12 col-md-12 col-12">
            <FormProvider {...methods}>
              <form className="row" noValidate autoComplete="off">
                <Grid container spacing={2}>
                    <Grid item xs={12} md={12} lg={6}>
                        <FormInput
                        name="quantity"
                        label={<IntlMessages id="core.dosage" />}
                        margin="normal"
                        disabled={disabled}
                        required={true}
                        errorobj={errors}
                        />
                    </Grid>
                    <Grid item xs={12} md={12} lg={6}>
                        <FormSelectAutoComplete
                            name="dosageUnitId"
                            label={<IntlMessages id="formula.dosageUnit" />}
                            options={getDosageUnitList(dosageInfo)}
                            margin="normal"
                            disabled={true}
                            required={true}
                            errorobj={errors}
                        />
                    </Grid>
                    
                    {(!dosageInfo 
                    || dosageInfo.productBehaviorId === ProductBehavior.remodulin 
                    || dosageInfo.productBehaviorId === ProductBehavior.dacepton ) &&
                      <React.Fragment>
                        <Grid item xs={12} md={12} lg={6}>
                          <FormInput
                            name="patientWeight"
                            label={<IntlMessages id="dosage.patientWeight" />}
                            margin="normal"
                            disabled={true}
                            required={true}
                            errorobj={errors}
                          />
                        </Grid><Grid item xs={12} md={12} lg={6}>
                          <FormInput
                            name="medicineConcentration"
                            label={<IntlMessages id="dosage.medicineConcentration" />}
                            margin="normal"
                            disabled={true}
                            />
                        </Grid>                      
                      </React.Fragment>
                    }
                    {(!dosageInfo || dosageInfo.productBehaviorId === ProductBehavior.remodulin || dosageInfo.productBehaviorId === ProductBehavior.dacepton) &&
                      <Grid item xs={12} md={12} lg={6}>
                        <FormInput
                          name="infusionRate"
                          label={<IntlMessages id="formula.infusionRate" />}
                          margin="normal"
                          disabled={true}
                          errorobj={errors}
                          />
                      </Grid>
                    }
                    { (!dosageInfo || (dosageInfo.formulaType === FormulaType.MIPRES &&
                        dosageInfo.productBehaviorId !== ProductBehavior.remodulin &&
                        dosageInfo.productBehaviorId !== ProductBehavior.dacepton)) &&
                      <Grid item xs={12} md={12} lg={6}>
                          <FormSelectAutoComplete
                            name="supplyFrequency"
                            label={<IntlMessages id="formula.supplyFrequency" />}
                            options={supplyFrequencyList}
                            margin="normal"
                            disabled={disabled}
                            required={true}
                            errorobj={errors}
                          />
                      </Grid>
                    }
                    <Grid item xs={12} md={12} lg={6}>
                        <FormDatePicker
                            name="startDate"
                            label={<IntlMessages id="core.startDate" />}
                            format="YYYY-MM-DD"
                            margin="normal"
                            //disableFuture={true}
                            disabled={disabled}
                            required={true}
                            errorobj={errors}
                        />
                    </Grid>
                </Grid>
              </form>
            </FormProvider>
            { !disabled &&
              <div className="col-lg-12 col-md-12 col-xs-12 text-center mt-4">
                <ButtonAsync onClick={handleSubmit(onSubmit)}
                  variant="contained"
                  color="primary"
                >
                  {<IntlMessages id="core.save" />}
                </ButtonAsync>
              </div>
            }
          </div>  
        </DialogContent>
          <DialogActions>
            <Button onClick={handleRequestClose} color="secondary">
              <IntlMessages id="core.close" />
            </Button>
          </DialogActions>
        </Dialog>  
    );
  };

  export default FormCRU;