import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import IntlMessages from "util/IntlMessages";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@material-ui/core";
import Form from "./Form";
import Table from "./Table";
import { 
  callApiAsync,
  postCreateUpdateDosage
} from "api/apiList";
import { ProductBehavior } from "constants/Enums";

const existItemsInImpact = (impactId, dataList) =>
  dataList.find((x) => x.impactId == impactId) != undefined;

const pendingInfusionRate = (prescriptionId, dataList) => {
  const a =  dataList.filter((x) => x.prescriptionId == prescriptionId);
  return (a.length == 1 && !a[0].infusionRate);
}

const DosageDialog = ({open, handleRequestClose, loadDosage, dosageInfo}) =>{
  const { selectedPrescription } = useSelector(({ management }) => management);
  
  const [openCRU, setOpenCRU] = useState(false);
  const [currentRowObj, setCurrentRowObj] = useState({});
  
  useEffect(() => {
    if(open)
      loadDosage();
  }, [open]);

  const handleOpenCRU = () => setOpenCRU(true);
  const handleRequestCloseCRU = () => setOpenCRU(false);

  const handleCreateUpdate = async (data) => {
    var isNew = data.id == 0;
    await callApiAsync(
      () => postCreateUpdateDosage(data),
      (newId) => {
        handleRequestCloseCRU();
        loadDosage();
      }
    );
  };

  const isRemodulin = (!dosageInfo 
                      || dosageInfo.productBehaviorId === ProductBehavior.remodulin 
                      || dosageInfo.productBehaviorId === ProductBehavior.dacepton );
  const pendingInfusion = !isRemodulin ? false : pendingInfusionRate(selectedPrescription.id, !dosageInfo || !dosageInfo.dosageList ? [] : dosageInfo.dosageList);
  const dosageInCurrentImpact = existItemsInImpact(selectedPrescription.impactId, !dosageInfo || !dosageInfo.dosageList ? [] : dosageInfo.dosageList);

  return (
    <div>
    <Dialog
      fullWidth={true}
      maxWidth="md"
      open={open}
      disableBackdropClick={true}
      onClose={handleRequestClose}
    >
      <DialogTitle>
        <IntlMessages id="core.dosage" />
      </DialogTitle>
      <DialogContent>
      <Table
        data={!dosageInfo || !dosageInfo.dosageList ? [] : dosageInfo.dosageList}
        handleOpenCRU={handleOpenCRU}
        setCurrentRowObj={setCurrentRowObj}
        currentImpact={selectedPrescription.impactId}
        dosageInfo={dosageInfo}
        showAddButton={ !pendingInfusion && !dosageInCurrentImpact }
        readOnly={false}
        pendingInfusionRate={pendingInfusion}
      />
      <Form
        rowObj={currentRowObj}
        impactId={selectedPrescription.impactId}
        openCRU={openCRU}
        handleRequestClose={handleRequestCloseCRU}
        handleCreateUpdate={handleCreateUpdate}
        dosageInfo={dosageInfo}
        readOnly={false}
        pendingInfusionRate={pendingInfusion}
      />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleRequestClose} color="secondary">
          <IntlMessages id="core.close" />
        </Button>
      </DialogActions>
    </Dialog>
    </div>
  );
};

export default DosageDialog;